.table_container {
    line-height: 1.25;
    min-height: 22em;
    overflow-x: auto;
    padding-bottom: 30px;
  }

table {
  /* border: 1px solid #ccc; */
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table tr {
  border-bottom: 1px solid #ddd;
  padding: 0.35em;
}

/* table th, table td:first-child {
  width: 100px;
}  */

table th,
table td {
  padding: 0.625em;
  text-align: start;
  width: 250px;
  border: 1px solid #d6d5d5;
}

table th {
  font-size: 0.92em;
  letter-spacing: 0.02em;
  /* text-transform: uppercase; */
  color: #5f5e5e;
}


@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    background-color: #f8f8f8;
    border: 3px solid #706f6f;
    /* border-bottom: 3px solid #706f6f; */
    display: block;
    margin-bottom: 2em;
    border-radius: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width:auto;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
    width:100%;
    border-top: none;
    border-left: none;
    border-right: none;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    /* text-transform: uppercase; */
    display: flex;
    /* justify-content: center;
    align-items: center; */
  }

  table td:last-child {
    border-bottom: 0;
  }
}

